var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.xsOnly)?_c('VNavigationDrawer',{attrs:{"color":"tt-white","width":"100%","bottom":"","fixed":"","temporary":""},model:{value:(_vm.showTrackPanel),callback:function ($$v) {_vm.showTrackPanel=$$v},expression:"showTrackPanel"}},[_c('VContainer',{staticClass:"py-6 px-4"},[(!_vm.loading)?_c('VRow',[(!_vm.newTracksPage)?[(_vm.paymentInfo)?_c('VCol',[_c('SimpleWidgetSalary',{attrs:{"meta":_vm.paymentInfo}})],1):_vm._e()]:_vm._e(),[(_vm.newTracksPage)?_c('VCol',[_c('WidgetScores',{attrs:{"meta":{
              bonus: _vm.bonus
            }}})],1):_c('VCol',[_c('SimpleWidgetScores',{attrs:{"meta":{
              bonus: _vm.bonus
            }}})],1)],(_vm.newTracksPage && !_vm.hideRating)?[_c('VCol',{attrs:{"cols":"12"}},[_c('LeaderBoardWidget')],1)]:_vm._e(),(_vm.accessToGoals && _vm.purposesList.length)?_c('VCol',{attrs:{"cols":"12"}},[_c('GoalsWidget',{attrs:{"purposes":_vm.purposesList}})],1):_vm._e(),_c('VCol',{attrs:{"cols":"12"}},[_c('EventsWidget')],1)],2):_vm._e()],1)],1):_c('AppAsideRight',[_c('VContainer',{staticClass:"py-6 px-4"},[(!_vm.loading)?_c('VRow',[(!_vm.newTracksPage)?[(_vm.paymentInfo)?_c('VCol',[_c('SimpleWidgetSalary',{attrs:{"meta":_vm.paymentInfo}})],1):_vm._e()]:_vm._e(),[(_vm.newTracksPage)?_c('VCol',[_c('WidgetScores',{attrs:{"meta":{
              bonus: _vm.bonus
            }}})],1):_c('VCol',[_c('SimpleWidgetScores',{attrs:{"meta":{
              bonus: _vm.bonus
            }}})],1)],(_vm.newTracksPage && !_vm.hideRating)?[_c('VCol',{attrs:{"cols":"12"}},[_c('LeaderBoardWidget')],1)]:_vm._e(),(_vm.accessToGoals && _vm.purposesList.length)?_c('VCol',{attrs:{"cols":"12"}},[_c('GoalsWidget',{attrs:{"purposes":_vm.purposesList}})],1):_vm._e(),_c('VCol',{attrs:{"cols":"12"}},[_c('EventsWidget')],1)],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }