<template>
  <VCard
    :elevation="0"
    class="simple-widget tt-light-red pale pa-4"
    data-test="simple-widget-salary"
  >
    <div class="d-flex justify-space-between">
      <div>
        <div
          data-test="simple-widget-salary-left-days"
          class="tt-text-title-1"
        >
          {{ $tc('pluralize.track.simpleWidget.days', meta.leftDays) }}
        </div>
        <div
          data-test="simple-widget-salary-text"
          class="tt-text-body-1 tt-light-mono-64--text"
        >
          <template v-if="meta.isPrepayment">
            <template v-if="meta.leftDays < 2">
              {{ $t('money.prepaid') }}
            </template>
            <template v-if="meta.leftDays > 1">
              {{ $t('money.before_prepaid') }}
            </template>
          </template>
          <template v-else>
            <template v-if="meta.leftDays < 2">
              {{ $t('money.salary') }}
            </template>
            <template v-if="meta.leftDays > 1">
              {{ $t('money.before_salary') }}
            </template>
          </template>
        </div>
      </div>
      <div class="simple-widget__image">
        <VImg
          contain
          class="simple-widget__image"
          :src="require('@/assets/pig.png')"
        />
      </div>
    </div>
  </VCard>
</template>

<script>
export default {
  name: 'SimpleWidgetSalary',
  props: {
    /**
     * @type {{
     * isPrepayment: boolean,
     * leftDays: number,
     * }}
     */
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
.simple-widget {
  border-radius: 8px!important;
  width: 100%;

  &__image {
    width: 96px;
    height: 96px;
  }
}
</style>
