<template>
  <VNavigationDrawer
    v-if="$vuetify.breakpoint.xsOnly"
    v-model="showTrackPanel"
    color="tt-white"
    width="100%"
    bottom
    fixed
    temporary
  >
    <VContainer class="py-6 px-4">
      <VRow v-if="!loading">
        <template v-if="!newTracksPage">
          <VCol v-if="paymentInfo">
            <SimpleWidgetSalary
              :meta="paymentInfo"
            />
          </VCol>
        </template>
        <template>
          <VCol v-if="newTracksPage">
            <WidgetScores
              :meta="{
                bonus
              }"
            />
          </VCol>
          <VCol v-else>
            <SimpleWidgetScores
              :meta="{
                bonus
              }"
            />
          </VCol>
        </template>
        <template v-if="newTracksPage && !hideRating">
          <VCol cols="12">
            <LeaderBoardWidget />
          </VCol>
        </template>
        <VCol
          v-if="accessToGoals && purposesList.length"
          cols="12"
        >
          <GoalsWidget :purposes="purposesList" />
        </VCol>
        <VCol cols="12">
          <EventsWidget />
        </VCol>
      </VRow>
    </VContainer>
  </VNavigationDrawer>
  <AppAsideRight v-else>
    <VContainer class="py-6 px-4">
      <VRow v-if="!loading">
        <template v-if="!newTracksPage">
          <VCol v-if="paymentInfo">
            <SimpleWidgetSalary
              :meta="paymentInfo"
            />
          </VCol>
        </template>
        <template>
          <VCol v-if="newTracksPage">
            <WidgetScores
              :meta="{
                bonus
              }"
            />
          </VCol>
          <VCol v-else>
            <SimpleWidgetScores
              :meta="{
                bonus
              }"
            />
          </VCol>
        </template>
        <template v-if="newTracksPage && !hideRating">
          <VCol cols="12">
            <LeaderBoardWidget />
          </VCol>
        </template>
        <VCol
          v-if="accessToGoals && purposesList.length"
          cols="12"
        >
          <GoalsWidget :purposes="purposesList" />
        </VCol>
        <VCol cols="12">
          <EventsWidget />
        </VCol>
      </VRow>
    </VContainer>
  </AppAsideRight>
</template>

<script>
import SimpleWidgetSalary from '@/components/track/SimpleWidgetSalary.vue';
import SimpleWidgetScores from '@/components/track/SimpleWidgetScores.vue';
import * as snamiApi from '@/services/api/snami';
import EventsWidget from '@/components/widgets/EventsWidget.vue';
import GoalsWidget from '@/components/widgets/GoalsWidget.vue';
import { mapActions, mapGetters } from 'vuex';
import { GET_PURPOSE_ACTION } from '@/plugins/vuex/actionTypes';
import { calculatePaymentInfo } from '@/helpers/dateHelpers';
import LeaderBoardWidget from '@/components/widgets/LeaderBoardWidget.vue';
import WidgetScores from '@/components/widgets/WidgetScores.vue';
import AppAsideRight from '@/components/app/AppAsideRight.vue';

export default {
  name: 'TrackAsideRight',
  components: {
    AppAsideRight,
    WidgetScores,
    LeaderBoardWidget,
    GoalsWidget,
    EventsWidget,
    SimpleWidgetScores,
    SimpleWidgetSalary,
  },
  inject: ['glFeatures'],
  data() {
    return {
      isPrepayment: null,
      leftDays: 0,
      prepaymentDay: 0,
      paymentDay: 0,
      loading: true,
      bonus: 0,
      showTrackPanel: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['options', 'accessToGoals']),
    ...mapGetters('settings', ['hideRating']),
    ...mapGetters('handbook', ['actualGoals']),
    ...mapGetters('user', ['staff']),
    purposesList() {
      const filteredPurposes = this.actualGoals.map((item) => item)
        .sort((a, b) => (this.$dayjs(a.deadline)
          .isAfter(this.$dayjs(b.deadline)) ? 1 : -1));
      if (filteredPurposes.length <= 3) {
        return filteredPurposes;
      }
      return filteredPurposes.slice(0, 3);
    },
    newTracksPage() {
      return this.glFeatures('new_tracks_page');
    },
    paymentInfo() {
      try {
        return this.calculatePaymentInfo({
          prepaymentInfo: {
            day: this.staff?.prepaymentDay,
            isPrepayment: true,
          },
          paymentInfo: {
            day: this.staff?.paymentDay,
            isPrepayment: false,
          },
        });
      } catch (e) {
        return false;
      }
    },
  },
  beforeMount() {
    this.$root.$on('click:show-widget-panel', this.toggleTrackPanel);
  },
  async created() {
    await this.fetch();
    this.getPurpose();
    this.loading = false;
  },
  beforeDestroy() {
    this.$root.$off('click:show-widget-panel', this.toggleTrackPanel);
  },
  methods: {
    toggleTrackPanel() {
      this.showTrackPanel = !this.showTrackPanel;
    },
    calculatePaymentInfo,
    ...mapActions('handbook', { getPurpose: GET_PURPOSE_ACTION }),
    async fetch() {
      const {
        data: {
          bonus,
        },
      } = await snamiApi.staffStatGet({ rateCount: 10 });
      this.bonus = bonus;
    },
  },
};
</script>
