<template>
  <VCard
    :elevation="0"
    class="simple-widget tt-light-yellow pale pa-4"
    data-test="simple-widget-scores"
  >
    <div class="d-flex justify-space-between">
      <div>
        <div
          data-test="simple-widget-scores-bonus"
          class="tt-text-title-1"
        >
          {{ meta.bonus }}
        </div>
        <div
          data-test="simple-widget-scores-bonus-name"
          class="tt-text-body-1 tt-light-mono-64--text"
        >
          {{ bonusName }}
        </div>
      </div>
      <div class="simple-widget__image">
        <VImg
          contain
          class="simple-widget__image"
          :src="require('@/assets/star.png')"
        />
      </div>
    </div>
  </VCard>
</template>

<script>
import { mapState } from 'vuex';
import pluralize from '@/helpers/pluralize';

export default {
  name: 'SimpleWidgetScores',
  props: {
    /**
     * @type {{
     * bonus: number,
     * }}
     */
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('settings', ['phrase']),
    bonusName() {
      const { one, two, many } = this.phrase.bonus;
      if (this.isCustomBonusName) {
        return pluralize(this.meta.bonus, [one, two, many]);
      }

      return this.$tc('pluralize.track.simpleWidgetScores.bonus', this.meta.bonus);
    },
    isCustomBonusName() {
      const { bonus } = this.phrase;
      return bonus.one && bonus.two && bonus.many;
    },
  },
};
</script>

<style scoped lang="scss">
.simple-widget {
  border-radius: 8px!important;
  width: 100%;

  &__image {
    width: 96px;
    height: 96px;
  }
}
</style>
