<template>
  <CardWrapper
    shadow="never"
    class="tt-light-yellow pale pa-4"
    data-test="leader-board-widget"
    :min-height="128"
  >
    <VRow justify="space-between">
      <VCol cols="auto">
        <VRow dense>
          <VCol>
            <span class="tt-text-title-1">2 место</span>
          </VCol>
        </VRow>
        <VRow dense>
          <VCol>
            <TTBtn
              text
              height="auto"
              class="pa-0"
              @click="handleClickShowRating"
            >
              <VRow
                no-gutters
                align="center"
              >
                <VCol cols="auto">
                  <span class=" tt-text-body-1 tt-light-mono-64--text">
                    в рейтинге
                  </span>
                </VCol>
                <VCol cols="auto">
                  <VIcon
                    dense
                    color="tt-light-mono-64"
                  >
                    fal fa-angle-right
                  </VIcon>
                </VCol>
              </VRow>
            </TTBtn>
          </VCol>
        </VRow>
      </VCol>
      <VCol cols="auto">
        <VIcon
          color="tt-light-yellow"
          size="48"
        >
          fas fa-trophy
        </VIcon>
      </VCol>
    </VRow>
  </CardWrapper>
</template>

<script>
import CardWrapper from '@/components/cards/CardWrapper.vue';

export default {
  name: 'LeaderBoardWidget',
  components: { CardWrapper },
  props: {
    position: {
      type: [Number, String],
      default: '-',
    },
  },
  methods: {
    handleClickShowRating() {
      this.$root.$emit('click:show-rating');
    },
  },
};
</script>

<style scoped>

</style>
