<template>
  <CardWrapper
    class="tt-light-yellow pale pa-4"
    data-test="simple-widget-scores"
    min-height="128"
  >
    <VRow
      justify="space-between"
      align="start"
    >
      <VCol cols="auto">
        <VRow dense>
          <VCol
            data-test="simple-widget-scores-bonus"
            class="tt-text-title-1"
          >
            {{ meta.bonus }}
          </VCol>
        </VRow>
        <VRow dense>
          <VCol
            data-test="simple-widget-scores-bonus-name"
            class="tt-text-body-1 tt-light-mono-64--text"
          >
            {{ bonusName }}
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="auto"
      >
        <VResponsive
          v-if="!newTracksPage"
          height="96"
        >
          <VImg
            contain
            :src="require('@/assets/star.png')"
          />
        </VResponsive>
        <VIcon
          size="48"
          color="tt-light-yellow"
        >
          fas fa-medal
        </VIcon>
      </VCol>
    </VRow>
  </CardWrapper>
</template>

<script>
import { mapState } from 'vuex';
import pluralize from '@/helpers/pluralize';
import CardWrapper from '@/components/cards/CardWrapper.vue';

export default {
  name: 'WidgetScores',
  components: { CardWrapper },
  inject: ['glFeatures'],
  props: {
    /**
     * @type {{
     * bonus: number,
     * }}
     */
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState('settings', ['phrase']),
    bonusName() {
      const { one, two, many } = this.phrase.bonus;
      if (this.isCustomBonusName) {
        return pluralize(this.meta.bonus, [one, two, many]);
      }

      return this.$tc('pluralize.track.simpleWidgetScores.bonus', this.meta.bonus);
    },
    isCustomBonusName() {
      const { bonus } = this.phrase;
      return bonus.one && bonus.two && bonus.many;
    },
    newTracksPage() {
      return this.glFeatures('new_tracks_page');
    },
  },
};
</script>

<style scoped lang="scss">
.simple-widget {
  border-radius: 8px!important;
  width: 100%;

  &__image {
    width: 96px;
    height: 96px;
  }
}
</style>
