<template>
  <VRow
    v-if="unreadedEvents.length"
    id="card-deck"
    ref="card"
    class="card-deck"
  >
    <VCol
      v-for="(event,i) in unreadedEvents"
      :key="i"
      cols="12"
      class="card-deck__item"
      :style="`transform:${getTranslate3D(i)}`"
    >
      <EventCard
        :id="`card-${i}`"
        :shadow="showShadow(i)"
        :task="event.task"
        class="pa-4"
        :title-length="35"
        :expires="event.expires"
        :card-type="event.task.type"
        @click.native="handleCardClick(event.task.id)"
      />
    </VCol>
  </VRow>
</template>

<script>
import EventCard from '@/components/cards/EventCard.vue';
import { mapActions, mapGetters } from 'vuex';
import { GET_EVENTS_ACTION } from '@/plugins/vuex/actionTypes';
import { R_APP_EVENTS } from '@/plugins/vue-router/routeNames';
// TODO Переделать компонент выпадающей колоды на обертку
export default {
  name: 'EventsWidget',
  components: { EventCard },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapGetters('handbook', ['unreadedEvents']),
  },
  mounted() {
    if (!this.unreadedEvents.length) {
      this.getEventsAction({ data: { include_inactive: true } });
    }
  },
  methods: {
    ...mapActions('handbook', { getEventsAction: GET_EVENTS_ACTION }),
    showShadow(index) {
      if (this.expanded) return 'always';
      if (this.unreadedEvents.length - index <= 3 && !this.expanded) {
        return 'always';
      }
      return 'never';
    },
    handleCardClick(id) {
      if (this.unreadedEvents.length === 1 || this.expanded) {
        this.$router.push({
          name: R_APP_EVENTS, params: { eventId: id },
        });
      } else this.onExpand();
    },
    onExpand() {
      if (this.expanded) return;
      const allItems = document.getElementsByClassName('card-deck__item');
      const cardDeck = document.getElementById('card-deck');
      let deckHeight = 0;
      allItems.forEach((item, index) => {
        deckHeight += 164;
        // eslint-disable-next-line max-len,vue/max-len,no-param-reassign
        item.style.transform = `translate3d(0, ${(this.unreadedEvents.length - index - 1) * 164}px, 0)`;
      });
      cardDeck.style.height = `${deckHeight}px`;
      this.expanded = true;
    },
    // TODO На будущее
    onCollapse() {
      this.$nextTick(() => {
        const allItems = document.getElementsByClassName('card-deck__item');
        allItems.forEach((item, index) => {
          // eslint-disable-next-line max-len,vue/max-len,no-param-reassign
          item.style.transform = this.getTranslate3D(index);
        });
        this.expanded = false;
      });
    },
    getTranslate3D(i) {
      if (this.unreadedEvents.length - i - 1 >= 3) return 'translate3d(0,0,0)';
      // eslint-disable-next-line max-len,vue/max-len
      return `translate3d(0, ${(this.unreadedEvents.length - i - 1) * 18}px, -${(this.unreadedEvents.length - i - 1) * 32}px)`;
    },
  },
};
</script>

<style scoped lang="scss">
.card{
  &-deck{
    cursor: pointer;
    transition: all .3s;
    transform: translate3d(0,0,0);
    position: relative;
    perspective: 500px;
    min-height: 164px;
    &__item{
      transition: all .3s;
      position: absolute;
    }
  }
}

</style>
