<template>
  <VRow>
    <VCol>
      <CardWrapper class="pa-4">
        <VRow
          dense
          justify="space-between"
          align="center"
        >
          <VCol
            class="tt-text-title-1"
            cols="auto"
          >
            {{ $t('menu.goals') }}
          </VCol>
          <VCol cols="auto">
            <RouterLink
              class="text-decoration-none tt-text-body-2"
              :to="{name : Names.R_APP_GOALS}"
              data-test="all-goals-button"
            >
              <VRow
                align="center"
                no-gutters
              >
                <VCol>
                  {{ $t('names.all') }}
                </VCol>
                <VCol>
                  <VIcon
                    class="pl-1"
                    small
                    color="tt-light-blue"
                  >
                    fal fa-angle-right
                  </VIcon>
                </VCol>
              </VRow>
            </RouterLink>
          </VCol>
        </VRow>
        <VRow no-gutters>
          <VCol
            cols="12"
          >
            <div
              v-for="(purpose,i) in purposes"
              :key="i"
            >
              <GoalCard
                shadow="never"
                class="py-4"
                :goal="purpose"
                :expires="purpose.expires"
              />
              <VDivider
                v-if="i+1!==purposes.length"
              />
            </div>
          </VCol>
        </VRow>
      </CardWrapper>
    </VCol>
  </VRow>
</template>

<script>
import CardWrapper from '@/components/cards/CardWrapper.vue';
import GoalCard from '@/components/cards/GoalCard.vue';

export default {
  name: 'GoalsWidget',
  components: { GoalCard, CardWrapper },
  inject: ['Names'],
  props: {
    purposes: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style scoped>

</style>
